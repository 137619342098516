import { render, staticRenderFns } from "./ArticleCardRow.vue?vue&type=template&id=6f7b1996&scoped=true"
import script from "./ArticleCardRow.vue?vue&type=script&lang=ts"
export * from "./ArticleCardRow.vue?vue&type=script&lang=ts"
import style0 from "./ArticleCardRow.vue?vue&type=style&index=0&id=6f7b1996&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7b1996",
  null
  
)

export default component.exports