import { render, staticRenderFns } from "./ArticleCardList.vue?vue&type=template&id=cb5cbdd8&scoped=true"
import script from "./ArticleCardList.vue?vue&type=script&lang=ts"
export * from "./ArticleCardList.vue?vue&type=script&lang=ts"
import style0 from "./ArticleCardList.vue?vue&type=style&index=0&id=cb5cbdd8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5cbdd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonArticleCardList: require('/var/www/modules/amplience/components/skeleton/ArticleCardList.vue').default})
